<template>
    <div v-if="comments[0]">

        <!-- List of Comments -->
        <TicketCommentsList
            :comments="comments[0].relatedEntries.edges"
            :ticket-agent-id="comments[0].node.ticketAgent[0].id"
            :ticket-contact-id="comments[0].node.ticketContact[0].id">
        </TicketCommentsList>

        <!-- Add Comment -->
        <!-- update our call from submit-comment to comment-submitted -->
        <SingleTextareaForm
            @comment-submitted="addComment"
            :ticket-id="ticketId"
            ticket-url="ticketUrl"
            :section-id="sectionId">
        </SingleTextareaForm>

    </div>
</template>

<script>
    import { axiosCraftQL, craftQLEndpoint } from "../main";
    import { queryCommentsRelatedToTicket } from "../queries/queryCommentsRelatedToTicket";
    import { mutationTicketComment } from "../queries/mutationTicketComment";
    import TicketCommentsList from "./TicketCommentsList";
    import SingleTextareaForm from "./SingleTextareaForm";

    export default {
      name: 'TicketComments',
      components: { TicketCommentsList, SingleTextareaForm },
      props: {
        ticketId: Number,
        ticketUrl: String,
        sectionId: Number,
      },
      created: function () {
        this.getComments();
      },
      data: function () {
        return {
          comments: {},
          newComment: {}
        }
      },
      methods: {
        getComments: function () {
          axiosCraftQL.post( craftQLEndpoint, {
            query: queryCommentsRelatedToTicket,
            variables: { id: this.ticketId }
          })
            .then(response => {
              this.comments = response.data.data.entriesConnection.edges;
            })
            .catch( error => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
        },
        addComment(bodyField) {
          axiosCraftQL.post( craftQLEndpoint, {
            query: mutationTicketComment,
            variables: {
              body: bodyField,
              ticketId: this.ticketId
            }
          })
            .then(response => {
              console.log('BodyField:' + bodyField);
              console.log(response);
              this.getComments();
            })
            .catch( error => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
        }
      }
    }
</script>
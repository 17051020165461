<template>
    <aads-price-points
            :enterprise-price="this.enterpriseMarkedUp"
            :small5-users-price="this.small5UsersMarkedUp"
            :small10-users-price="this.small10UsersMarkedUp"
            :small-unlimited-users-price="this.smallUnlimitedUsersMarkedUp"
    ></aads-price-points>
</template>

<script>
export default {
  name: 'AadsPage',

  props: {
    markup: Number,
    enterprise: Number,
    small5Users: Number,
    small10Users: Number,
    smallUnlimitedUsers: Number,
    enterpriseProducts: Object,
    smallProducts: Object
  },


  data: function () {
    return {
      'usdRate': 0
    }
  },

  created: function () {
    this.getExchangeRates();
  },

  computed: {
    enterpriseUsd: function () {
      return this.enterprise * this.usdRate;
    },
    enterpriseMarkedUp: function () {
      return Math.round(this.enterpriseUsd + (this.enterpriseUsd * this.markup));
    },
    small5UsersUsd: function () {
      return this.small5Users * this.usdRate;
    },
    small5UsersMarkedUp: function () {
      return Math.round(this.small5UsersUsd + (this.small5UsersUsd * this.markup));
    },
    small10UsersUsd: function () {
      return this.small10Users * this.usdRate;
    },
    small10UsersMarkedUp () {
      return Math.round(this.small10UsersUsd + (this.small10UsersUsd * this.markup));
    },
    smallUnlimitedUsd () {
      return this.smallUnlimitedUsers * this.usdRate;
    },
    smallUnlimitedUsersMarkedUp () {
      return Math.round(this.smallUnlimitedUsd + (this.smallUnlimitedUsd * this.markup));
    }
  },

  methods: {
    getExchangeRates: function () {
      this.$axios.get('https://api.exchangeratesapi.io/latest')
          .then((response) => {
            console.log(response);
            this.usdRate = response.data.rates.USD;
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>
const queryCommentsRelatedToTicket = `query fetchRelatedComments($id:[Int]) {
  entriesConnection(id:$id) {
    edges {
      node {
        ...on Tickets {
        ticketContact { id }
        ticketAgent { id }
        }
      }
      relatedEntries(section:comments) {
        edges {
          node {
            ...on Comments { author { name id } body postDate }
          }
        }
      }
    }
  }
}`;

export { queryCommentsRelatedToTicket };
<template>
    <div v-if="comments">
        <TicketComment
            v-for="comment in sortedComments"
            :key="comment.id"
            :comment="comment"
            :ticket-agent-id="ticketAgentId"
            :ticket-contact-id="ticketContactId">
        </TicketComment>
    </div>
</template>

<script>
    import TicketComment from "./TicketComment";
    export default {
      name: 'TicketCommentsList',
      components: { TicketComment },
      props: {
        comments: Array,
        ticketAgentId: Number,
        ticketContactId: Number
      },
      computed: {
        sortedComments () {
          return this.comments.sort((a,b) => {
            return a.node.postDate - b.node.postDate;
          });
        }
      }
    }
</script>
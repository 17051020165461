<template>
    <div v-if="comment" class="card mt-4">
        <!-- Comment Header -->
        <div class="bg-primary-lightest flex justify-between p-4 text-grey-600 text-xs">
            <div>
                <a class="font-bold text-grey-600 no-underline hover:underline"
                   :href="'helpdesk/users/' + this.comment.node.author.id ">
                    {{ this.comment.node.author.name }}
                </a>
                commented on {{ date }}
            </div>
            <div>
                <span v-if="ticketAgentId === comment.node.author.id" class="border border-grey-300 px-2 py-1 rounded">agent</span>
                <span v-if="ticketContactId === comment.node.author.id" class="border border-grey-300 px-2 py-1 rounded">contact</span>
            </div>
        </div>
        <!-- Comment Body -->
        <div class="p-4 leading-normal text-sm">
            {{ this.comment.node.body }}
        </div>
    </div>
</template>

<script>
    export default {
      name: 'TicketComment',
      props: {
        comment: Object,
        ticketAgentId: Number,
        ticketContactId: Number,
      },
      computed: {
        // TODO eventually I'd like to use something like https://moment.github.io/luxon/index.html
        date() {
          let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          // why multiply by 1000? See: https://stackoverflow.com/a/847196/3415998
          let timestamp = new Date(this.comment.node.postDate * 1000);
          let month = timestamp.getMonth();
          let day = timestamp.getDate();
          let year = timestamp.getFullYear();
          // https://stackoverflow.com/a/36822046/3415998
          let time = timestamp.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          return `${months[month]} ${day}, ${year} at ${time}`;
        }
      }
    }
</script>
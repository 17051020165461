import Vue from 'vue'
import qs from 'qs'
import Axios from 'axios'
import Components from './components'
import './app.scss'
// import {textareaFocus} from './js/textarea-focus.js';
// textareaFocus();

// Axios --------------------------------------------------
Axios.defaults.baseURL = window.Craft.baseUrl;
Axios.defaults.headers.common['Accept'] = 'application/json';
// Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
window.axios = Axios;

Axios.interceptors.request.use(config => {
  if (config.data instanceof FormData) {
    config.data.append(Craft.csrfTokenName, Craft.csrfToken);
  }
  else if (config.data === Object(config.data)) {
    config.data = qs.stringify(Object.assign({[Craft.csrfTokenName]: Craft.csrfToken}, config.data));
  }
  return config;
});


// Vue ----------------------------------------------------
Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;

new Vue({
  el: '#app',
  delimiters: ['${', '}'],
  data: {
    selected: ''
  }
});

<template>
    <div class="flex flex-wrap border-b-4 border-grey-darkest">
        <div class="w-full md:w-1/2 hover:bg-grey-lightest p-6 transition">
            <h2 class="text-4xl">Small Terminal Server</h2>
            <div class="flex flex-wrap justify-between pt-2">
                <div>
                    <div class="text-6xl">
                        <span class="font-thin">&dollar;</span><span class="font-bold text-black">{{small5UsersPrice}}</span>
                    </div>
                    <div class="text-grey-darker pt-2 text-lg">5 Users</div>
                </div>
                <div>
                    <div class="text-6xl">
                        <span class="font-thin">&dollar;</span><span class="font-bold text-black">{{small10UsersPrice}}</span>
                    </div>
                    <div class="text-grey-darker pt-2 text-lg">10 Users</div>
                </div>
                <div>
                    <div class="text-6xl">
                        <span class="font-thin">&dollar;</span><span class="font-bold text-black">{{smallUnlimitedUsersPrice}}</span>
                    </div>
                    <div class="text-grey-darker pt-2 text-lg">&infin; Users</div>
                </div>
            </div>
        </div>
        <div class="w-full md:w-1/2 hover:bg-grey-lightest p-6 text-right transition">
            <h2 class="text-4xl">Enterprise Terminal Server</h2>
            <div class="text-6xl py-2">
                <span class="font-thin">&dollar;</span><span class="font-bold text-black">{{enterprisePrice}}</span>
            </div>
            <div class="text-grey-darker text-lg">&infin; Users and more</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AadsPricePoints',

  props: {
    enterprisePrice: Number,
    small5UsersPrice: Number,
    small10UsersPrice: Number,
    smallUnlimitedUsersPrice: Number
  },

}
</script>
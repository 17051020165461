<template>
    <div class="card mt-4 p-4 transition textarea-container">

        <textarea v-model="bodyField" placeholder="Your enlightened comment goes here..." class="w-full text-sm focus:outline-none textarea-has-container"></textarea>
        <div class="mt-3 text-right">
            <button class="btn bg-grey-500 font-bold text-sm text-white hover:bg-grey-600 transition" type="submit" @click="submitComment(bodyField)">Comment</button>
        </div>

    </div>
</template>

<script>
    export default {
      name: 'SingleTextareaForm',
      props: {
        entryId: Number,
        entryUrl: String,
        sectionId: Number
      },
      data() {
        return {
          bodyField: '',
        }
      },
      methods: {
        // don't need to include `bodyField` in method argument
        submitComment() {
          if (this.bodyField) {
            console.log('from child: ' + this.bodyField);
            // renamed 'submit-comment' to 'comment-submitted' for clarity
            // specify that we are sending along `this.bodyField` from our data object
            this.$emit('comment-submitted', this.bodyField);
          }
        }
      },
    }
</script>